import React, { Component } from 'react'
import { server,help_email } from './components/environ';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      msg : ''
    }
  }

  componentDidCatch(error, info) {
    
    console.log("Some error occured.",error.toString()) 
    var data = { error : error.toString(), info : null, url : this.props.webaddress };
    data.access_token = localStorage.getItem('/user/access_token');
    var options = {   method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) };

    let url = server + '/error/add'

    if (!window.location.href.includes("localhost"))
      fetch(url,options)
      .then(response => response.json())
      .then(jsondata => {});

    this.setState(state => ({...state, hasError: true }))
    setInterval(this.timer, 1500);
  }

  timer = () => { this.setState({ msg : "Something went wrong, it will get resolved on its own in sometime. If it persists, please contact " + help_email + '.'}) }


  render() {
    
  //  this.state.hasError && window.alert('some error occurred')

    if (this.state.hasError)
      return (
      <React.Fragment>
      <Container>
      <Grid container justifyContent='center' alignItems='center' >
      <Grid item style={{ marginTop : '60px', width : '400px', textAlign : 'center'}}>
      <Typography  variant='caption' align='center' children={this.state.msg}/>
      </Grid>
      </Grid>
      </Container>
      </React.Fragment>
    )
    else
      return this.props.children
  }
}
export default ErrorBoundary
