import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { server,local,parrot_key,site_id } from './components/environ';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {blue,pink,grey} from '@material-ui/core/colors/';
import './App.css';

const   PricingWeb          = lazy(() =>  import('./components/vendor/signin/pricing-web'));
const   ErrorPage           = lazy(() =>  import('./components/vendor/signin/ErrorPage'));
const   ChangePassword      = lazy(() =>  import('./components/vendor/signin/ChangePassword'));
const   UserVerified        = lazy(() =>  import('./components/vendor/signin/UserVerified'));
const   SignInForm          = lazy(() =>  import('./components/vendor/signin/SignInForm'));
const   PaperBase           = lazy(() =>  import('./components/vendor/PaperBase'));
const ExtraPages            = lazy(() => import('./components/vendor/signin/ExtraPages'));

import { GoogleOAuthProvider } from '@react-oauth/google';
import ErrorBoundary   from  './ErrorBoundary';



function App() {

  return (
    <Router>
    <ErrorBoundary>
    <Suspense fallback={<div></div>}>
    <Switch> 

      <Route  path="/pricing"           component={PricingWeb} />
      <Route  path="/changepassword"    component={ChangePassword}/>
      <Route  path="/userverified"      component={UserVerified}/>
      <Route  path="/errorpage"         component={ErrorPage} />
      <Route  path="/support"           component={() => (<ExtraPages path="/support" />)}/> 
      <Route  path="/privacy"           component={() => (<ExtraPages path="/privacy" />)}/> 
      <Route  path="/appPrivacy"        component={() => (<ExtraPages path="/privacy" />)}/> 
      <Route  path="/terms"             component={() => (<ExtraPages path="/terms" />)}/> 
      <Route  path="/deleteAccount"     component={() => (<ExtraPages path="/deleteAccount" />)}/>  
      <Route  path="/refund"            component={() => (<ExtraPages path="/refund" />)}/>  

      { (window.location.origin.includes('smartbin.doitzero.com') || window.location.origin.includes('smartbin.rebin.in') || window.location.origin.includes(':3006'))  &&
        <React.Fragment>
        <Route  exact path="/"          component={() => (<PaperBase appPage='/smartbin' /> )} />
        <Route  path="/smartbin"        component={() => (<PaperBase appPage='/smartbin' />)}  />
        <Route  path="/signin"          component={SignInForm}/>
        </React.Fragment>
      }
    
    </Switch>
    </Suspense>
    </ErrorBoundary>
    </Router>
  );
}

export default App

