function getSiteIdentifier (domain_add) { 
  var href = window.location.href
  
  if (href.includes("smartbin.doitzero.com") || href.includes("smartbin.rebin.in") || href.includes(":3006"))
    return 'smartbin'
  else if (href.includes("something.else") || href.includes("localhost:3008"))
    return 'somethingelse'
 }

function getSiteAdd(site_id) {
  if (site_id == 'smartbin')
    return 'doitzero.com'
  else if (site_id == 'somethingelse')
    return 'something.else'
}

function getSiteHome(site_id) {
  if (site_id == 'smartbin')
    return 'doitzero.com'
  else if (site_id == 'somethingelse')
    return 'something.else' 
}

function getSiteEmail(site_id) {
  if (site_id == 'smartbin')
    return 'support@doitzero.com'
  else if (site_id == 'somethingelse')
    return 'support@something.else'
}

export const site_id = getSiteIdentifier(window.location.href)

function getSiteName(site_id) {
  if (site_id == 'smartbin')
    return 'SmartBin'
  else if (site_id == 'somethingelse')
    return 'somethingelse'
}


function getDomain(site_id) {
  if (site_id == 'smartbin')
    return 'doitzero.com'
  else if (site_id == 'somethingelse')
    return 'something.else'
 }

function getSiteLogo(site_id) {
  if (site_id == 'smartbin')
    return '/sw1024bg.png'
  else if (site_id == 'somethingelse')
    return './icon192.png'
}

function getSiteDashboard(site_id) {
  if (site_id == 'smartbin')
    return '/smartbin'
  else if (site_id == 'somethingelse')
    return '/board'
}

function getGoogleClientID(site_id) {
  if (site_id == 'smartbin')
    return '558464574687-dh7kr0d4nrrg98a7ef81hi6ctbj1i2p2.apps.googleusercontent.com'
  else if (site_id == 'somethingelse')
    return '471211060005-qrqtfmg0ohtt2p3g26vp0pg76fuk4nj7.apps.googleusercontent.com'
}

function getGoogleClientSecret(site_id) {
  if (site_id == 'smartbin')
    return ''
  else if (site_id == 'somethingelse')
    return '471211060005-qrqtfmg0ohtt2p3g26vp0pg76fuk4nj7.apps.googleusercontent.com'
 
}

function getEnableCustomSignup(site_id) {
  if (site_id == 'smartbin')
    return true
  else if (site_id == 'somethingelse')
    return false
}

function getPaypalClientId(site_id) {
    return ''
}

function getRazorpayClientId(site_id) {
    return ''
}


function getSEO(site_id) {
  var seoTitle = 'somethingelse'
  var seoDescription = 'somethingelse is a task manager.'
  var seoFavIcon     ="./favicon.ico"


  if(site_id == 'smartbin' ){
     seoTitle        = 'SmartBin'
     seoDescription  = "SmartBin"
     seoFavIcon      ="./favicon.ico"
  }
  return { seoTitle : seoTitle, seoDescription : seoDescription, seoFavIcon : seoFavIcon}
}



function getOrgDetails (site_id) {
  
  site_id = window.location.href.includes('appPrivacy') ? 'appbots' : site_id

  if (site_id == 'smartbin')
    return {
      company_name : "",
      website : "doitzero.com",
      address : "",
      email   : "support@doitzero.com",
      state   : '',
      termsLink :   'https://' + 'smartbin.doitzero.com' + '/terms', 
      privacyLink : 'https://' + 'smartbin.doitzero.com' + '/privacy',
      refundPolicyLink : 'https://' + 'smartbin.doitzero.com' + '/refund',
      icoEmail : 'appeals@doitzero.com' 

    }

}

export function signUpContent (site_id) { 

  var d = [{ 
    title : 'Manage IOT enabled Bins',
    content : 'Add IOT enabled bins and monitor them.',
  },{
    title : 'Check Fill Level',
    content : 'Check the status of bins location and their fill level.',
  },{
    title : 'Manage Field Staff',
    content : 'Assign field staff to clear the bins.',
  },];

  var content = { smartbin : d }

  return content[site_id]
}

export const site_name              = getSiteName(site_id)
export const site_add               = getSiteAdd(site_id)
export const site_logo              = getSiteLogo(site_id)
export const site_dashboard         = getSiteDashboard(site_id)
export const site_home              = getSiteHome(site_id)
export const site_email             = getSiteEmail(site_id)
export const enableCustomSignup     = getEnableCustomSignup(site_id)
export const googleClientID         = getGoogleClientID(site_id)
export const googleClientSecret     = getGoogleClientSecret(site_id)

export const org_details            = getOrgDetails(site_id)
export const paypalClientId         = getPaypalClientId(site_id)  
export const razorpayClientId       = getRazorpayClientId(site_id)  
export const site_colors            = getSiteColors(site_id)
export const domain                 = getDomain(site_id)


export const domain_name            = 'https://www.' + domain; 
export const server                 = 'https://' + ( window.origin.includes('localhost') ? 'api-smartbin-prod.' : 'api-smartbin-prod.' )  + domain
export const help_email             = 'support@' + domain;

export const dashColor = {
  ptColor               : '#000000',
  pttColor              : '#000000',
  bgPaperLight          : '#fafafb', 
  sidePaneColor         : '#ffffff', 
  darkBool              : 'light',
  stColor               : '#ffffff'
}


export const seoDescriptors = getSEO(site_id)

export function setDefaultSEO() {
  document.title                                                  = seoDescriptors.seoTitle
  document.querySelector("link[rel*='icon']").href                = seoDescriptors.seoFavIcon
  document.getElementsByTagName('meta')["description"].content    = seoDescriptors.seoDescription
  document.getElementsByTagName('meta')["og:title"].content       = seoDescriptors.seoTitle;
  document.getElementsByTagName('meta')["og:description"].content = seoDescriptors.seoDescription;
}




var inProd =( (window.location.href).includes("localhost")  || window.location.href.includes("192.168") )  ? false : true;
export const local = inProd ? false : true;


//parrot
function ft (x,y) { return x }
export var p = { t : ft }
export const parrot_key = '23zdQyXPMFzxDvxNST3f8'; 

//crisp
export function getCrispWebsiteID() {
    return (store_type == "ecommerce") ? "97a7279c-346e-459f-b525-13a9a1516b8d" 
                                       : "ae584e9f-99e0-4878-83ab-1d9ed1a0a8cc";
}

//paddle
export const paddle_vendor_id = 115440; 

//insepectlet
export function getInspectletWID() {
    return (store_type == "ecommerce") ? 825117434 : 1359076317;
}

// this is for opening menu where
export const store_type =  "software"; 

//export const enablePhoneLogin = false;  //keep it disabled till sms are enabled
export const enablePhoneLogin = (store_type == "ecommerce") ? true : false;

export const enableGoogleLogin      = false
export const enableStaffLogin       = false

export const MAX_INT = 1000000000; //2^31-1
export const RZP_RN_SDK = false;




export function subscriptionPlans (p) { 
  var subscriptionPlans = [{ 
    plan_name : 'FREE TRIAL',
    plan_price : '$ 1',
    plan_duration : 'per month',
    feature1 : 'Single Location',
    feature2 : 'Digital Ordering',
    feature3 : 'Email Support'
  },{
    plan_name : 'BUSINESS',
    plan_price : '$ 10',
    plan_duration : 'per month',
    feature1 : 'Multiple Locations (upto 10)',
    feature2 : 'Includes Order Management',
    feature3 : 'Email Support'
  },{
    plan_name : 'PREMIUM',
    plan_price : '$ 100',
    plan_duration : 'per month',
    feature1 : 'Multiple Locations (upto 100)',
    feature2 : 'Includes Order Management',
    feature3 : 'Premium Support 24/7'
  },
  ];
  return subscriptionPlans;
}


export function blog_data (p) { 
 var blog_data = []
 return blog_data;
}

export const test_mode = (local == false) ? 0 : 1;
//export const test_mode = 0 ;
export const available_payment_modes = {}
export const apmd = {}
export const invoice_specs_base = []


export function getMOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent))  // Windows Phone must come first because its UA also contains "Android"
      return "Windows Phone";
  if (/android/i.test(userAgent))
      return "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) // iOS detection from: http://stackoverflow.com/a/9039885/177710
      return "iOS";
  return "unknown";
}



export function getSiteColors (site_id) {
  if(site_id == 'bots' ){
    return getColors(0)
  }
  return getColors(0)
}


export function getColors (color) {
// base -> task -> menu -> button
  const colorLib = [
    {
    baseBackground                              : '#121720', //
    buttonBackgroundSelected                    : '#121720',

    buttonBackgroundHover                       : '#161c26', 
    taskBackground                              : '#161c26', //
    taskBackgroundTouchMove                     : '#161c26',

    taskBackgroundHover                         : '#20232c',
    createEditTaskBackgroundButton              : '#20232c',
    menuBackground                              : '#20232c',
    
    taskBackgroundCategoryHeader                : '#242933',
    createEditTaskBackground                    : '#242933', //
    createEditTaskBackgroundButtonHover         : '#242933',
    buttonBackground                            : '#242933',

    
    textColor                                   : 'rgb(191, 199, 210)',

    addNewBackground                            : '#8c64eb',
    addNewText                                  : 'white',
    addNewHover                                 : '#7c54db',

    taskBackgroundGreen                         : '#337733',
    taskBackgroundRed                           : '#773333',


  }, 
    {
    baseBackground              : '#080808', //0
   
    taskBackground                              : '#101010', //1
    taskBackgroundHover                         : '#181818', //2
    taskBackgroundTouchMove                     : '#383838', //5
    taskBackgroundCategoryHeader                : '#202020', //4

    createEditTaskBackground                    : '#202020', //3
    createEditTaskBackgroundButton              : '#303030', 
    createEditTaskBackgroundButtonHover         : '#404040',

    menuBackground              : '#181818', //2
    buttonBackground            : '#202020', //3
    buttonBackgroundHover       : '#282828',
    buttonBackgroundSelected    : '#303030',
    
    textColor                   : '#bbbbbb',

    addNewBackground            : '#282828',
    addNewText                  : '#f0f0f0',
    addNewHover                 : '#404040',

    taskBackgroundGreen                         : '#337733',
    taskBackgroundRed                           : '#773333',
  }, 
  {
    baseBackground              : '#fefefe',

    taskBackground                              : '#f6f9f9',
    taskBackgroundHover                         : '#f0f3f3',
    taskBackgroundTouchMove                     : '#f0f3f3',
    taskBackgroundCategoryHeader                : '#f0f3f3', //4

    
    createEditTaskBackground                    : '#f0f3f3',
    createEditTaskBackgroundButton              : '#e0e3e3',
    createEditTaskBackgroundButtonHover         : '#d0d3d3',

    menuBackground              : '#f1f4f4',
    buttonBackground            : '#e0e3e3',
    buttonBackgroundHover       : '#eaeded',
    buttonBackgroundSelected    : '#e7eaea',
   
    textColor                   : '#505050',

    addNewBackground            : '#272727',
    addNewText                  : '#f0f0f0',
    addNewHover                 : '#404040',

    taskBackgroundGreen                         : '#ddeedd',
    taskBackgroundRed                           : '#eedddd',
  }]
    return colorLib[color]
  }



